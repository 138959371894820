import React from 'react'
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  Row
} from 'react-bootstrap'
import { useGetCountriesQuery } from '../../../../components/api'
import Layout from '../../../../components/Layout'
import NotificationToaster from '../../../../components/NotificationToaster'
import Spinner from '../../../../components/spinner'
import { getLabelFromArray } from '../../../../components/static'
import dropdownSvg from '../../../../images/dropdown.svg'
import dropdownAppliedSvg from '../../../../images/dropdownAppliedArrowDown.svg'
import dropdownSuspendSvg from '../../../../images/dropdownSuspend.svg'
import {
  useUpdateParticipantCompanyDetail,
  useViewParticipantCompanyQuery
} from '../../../../utils/api'
import ParticipantsChangeStatusModal from '../../kitchensink/participantsChangeStatusModal'
import VendorDeleteParticipantModal from '../../kitchensink/vendorDeleteParticipant'

const Page = (props) => {
  const { listingId } = props
  const [editing, setEditing] = React.useState(false)
  const [nickName, setNickName] = React.useState('')
  const [accountStatus, setAccountStatus] = React.useState('')
  const [showToast, setShowToast] = React.useState(false)
  const [updateFail, setUdateFail] = React.useState(false)
  const [isShowToaster, setIsShowToaster] = React.useState(false)
  // ANCHOR Modals
  const [showChangeStatusModal, setShowChangeStatusModal] =
    React.useState(false)
  const [openDeleteParticipantModal, setOpenDeleteParticipantModal] =
    React.useState(false)

  // ANCHOR Queryies and mutations
  const countriesQuery = useGetCountriesQuery()
  const viewParticipantCompany = useViewParticipantCompanyQuery({
    listingId: listingId
  })
  const updateParticipantCompanyDetail = useUpdateParticipantCompanyDetail()

  // ANCHOR use a mutation to update the nickname
  const updateNickName = () => {
    if (nickName === viewParticipantCompany.data.nickName) {
      setEditing(false)
    } else if (nickName !== '') {
      const updateList = [{ id: listingId, nickName: nickName }]
      // clean up local state before submit
      setNickName('')
      updateParticipantCompanyDetail.mutateAsync(updateList).then(() => setIsShowToaster(true)).catch(() => setIsShowToaster(true))
      setEditing(false)
      //   viewParticipantCompany.refetch()
    }
  }

  const handleStatusChange = (status) => {
    let currentStatus =
      viewParticipantCompany.data.status === 'active' ? 'suspend' : 'active'
    if (status) {
      currentStatus = status // overide with the value sent from the dropdown
    }
    setAccountStatus(currentStatus)
    setShowChangeStatusModal(true)
  }

  const downloadObjectAsJson = (exportObj, exportName, mimetype) => {
    const dataStr = 'data:' + mimetype + ';base64,' + exportObj
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', exportName)
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  return (
    <Layout>
      <Layout.Title showBackButton>
          <h5 className="pe-4 py-0">
            {viewParticipantCompany.isSuccess &&
              viewParticipantCompany.data.legalName}
          </h5>

          {viewParticipantCompany.isSuccess && (
            <Row>
              <span className="font-weight-bold mr-2 d-flex align-items-center ">
                Company Status:
              </span>
              <div id="custom-drop-down-item-style">
                <Dropdown className="mr-3">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    variant="light"
                    className={
                      viewParticipantCompany.data.status === 'active'
                        ? 'status-active'
                        : viewParticipantCompany.data.status === 'suspended'
                          ? 'status-suspend'
                          : viewParticipantCompany.data.status === 'applied'
                            ? 'status-applied'
                            : 'status-suspend'
                    }
                  >
                    {viewParticipantCompany.data.status === 'active'
                      ? (

                        <div> Active
                          <img
                            src={dropdownSvg}
                            className="rounded ml-2"
                            alt=""
                            height="9"
                          />
                        </div>
                        )
                      : viewParticipantCompany.data.status === 'suspended'
                        ? (
                          <div>
                            Suspended
                            <img
                              src={dropdownSuspendSvg}
                              className="rounded ml-2"
                              alt=""
                              height="9"
                            />
                          </div>

                          )
                        : viewParticipantCompany.data.status === 'applied'
                          ? (
                            <div>
                              Applied
                              <img
                                src={dropdownAppliedSvg}
                                className="rounded ml-2"
                                alt=""
                                height="9"
                              />
                            </div>

                            )
                          : (
                            <div>
                              Declined
                              <img
                                src={dropdownSuspendSvg}
                                className="rounded ml-2"
                                alt=""
                                height="9"
                              />
                            </div>

                            )}

                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mr-3">
                    {
                      (viewParticipantCompany.data.status === 'active' || viewParticipantCompany.data.status === 'suspended') &&
                      <Dropdown.Item
                        className="mr-3"
                        onClick={() => handleStatusChange()}
                      >
                        {viewParticipantCompany.data.status === 'active'
                          ? 'Suspend'
                          : 'Active'}
                      </Dropdown.Item>
                    }
                    {
                      viewParticipantCompany.data.status === 'applied' &&
                      <>
                        <Dropdown.Item
                          className="mr-3"
                          onClick={() => handleStatusChange('approve')}
                        >
                          Approve
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="mr-3"
                          onClick={() => handleStatusChange('decline')}
                        >
                          Decline
                        </Dropdown.Item>
                      </>
                    }
                    {
                      (viewParticipantCompany.data.status === 'declined') &&
                      <Dropdown.Item
                        className="mr-3"
                        onClick={() => handleStatusChange('active')}
                      > Active
                      </Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Row>
          )}
      </Layout.Title>

      <Layout.CardGrey>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className=" px-2 pt-5 px-md-5 font-weight-bold">
            Company Details{' '}
          </h4>
          <NotificationToaster
            showToast={isShowToaster}
            setShowToasFn={setIsShowToaster}
            isSuccess={updateParticipantCompanyDetail.isSuccess}
            message={updateParticipantCompanyDetail.isSuccess ? 'Change successful!' : 'Failed to change'} />
          {editing
            ? (
              <div className="mx-2 mt-5 mx-md-5">
                <Button
                  className="mr-3"
                  variant="outline-primary"
                  onClick={() => {
                    setEditing(false)
                    setNickName(viewParticipantCompany.data.nickName)
                  }}
                >
                  Cancel
                </Button>
                {updateParticipantCompanyDetail.isLoading
                  ? (
                    <Spinner />
                    )
                  : (
                    <Button
                      onClick={() => {
                        updateNickName()
                      }}
                    >
                      Save
                    </Button>
                    )}
              </div>
              )
            : (
              <Button
                className="mx-2 mt-5 mx-md-5"
                variant="outline-primary"
                onClick={() => setEditing(true)}
              >
                Edit Nickname
              </Button>
              )}
        </div>
        <Row>
          <Col>
            {viewParticipantCompany.isLoading && (
              <div className="d-flex justify-content-center p-5">
                <Spinner />
              </div>
            )}
            {viewParticipantCompany.isError && (
              <Alert variant="danger" className="mb-0 mt-3 text-center">
                {'' + viewParticipantCompany.error.message}
              </Alert>
            )}
            {viewParticipantCompany.isSuccess && (
              <div className="px-2 px-md-5 d-flex flex-column flex-grow-1 my-3">
                {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}

                <p className="mb-3 font-weight-bold">Company Information</p>
                {/* form */}
                <Form className="d-flex flex-column flex-grow-1 justify-content-between">
                  <div>
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          viewParticipantCompany.data.legalName
                            ? viewParticipantCompany.data.legalName
                            : ''
                        }
                        disabled
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Company Nickname</Form.Label>
                      {updateParticipantCompanyDetail.isFetching ||
                        viewParticipantCompany.isFetching
                        ? (
                          <div className="d-flex justify-content-center">
                            <Spinner />
                          </div>
                          )
                        : (
                          <Form.Control
                            type="text"
                            value={
                              nickName || viewParticipantCompany.data.nickName
                            }
                            onChange={(e) => {
                              setNickName(e.target.value)
                            }}
                            autoFocus={true}
                            disabled={!editing}
                          />
                          )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Company Registration Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          viewParticipantCompany.data.registrationNumber || ''
                        }
                        disabled
                      />
                    </Form.Group>
                    <Row className="d-flex align-items-center justify-content-between px-3">
                      <Form.Group style={{ flex: 1 }}>
                        <Form.Label>Company Registration Document</Form.Label>
                        <Form.Control
                          // required
                          type="text"
                          required
                          value={
                            viewParticipantCompany.data.registrationDocument
                              .originalname || 'No file'
                          }
                          disabled
                        />
                      </Form.Group>

                      <Button
                        variant="outline-primary ml-2 mt-1"
                        onClick={() => {
                          downloadObjectAsJson(
                            viewParticipantCompany.data.registrationDocument
                              .base64content,
                            viewParticipantCompany.data.registrationDocument
                              .originalname,
                            viewParticipantCompany.data.registrationDocument
                              .mimetype
                          )
                        }}
                      >
                        Download
                      </Button>
                    </Row>
                  </div>
                  <Form.Group>
                    <Form.Label>Company Tax ID</Form.Label>
                    <Form.Control
                      type="text"
                      value={viewParticipantCompany.data.taxId}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Company Website</Form.Label>
                    <Form.Control
                      type="text"
                      value={viewParticipantCompany.data.website}
                      disabled
                    />
                  </Form.Group>
                </Form>
              </div>
            )}
          </Col>
          <Col>
            {' '}
            {viewParticipantCompany.isSuccess && (
              <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-3">
                {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
                <div className="d-flex justify-content-between">
                  <p className="mb-3 font-weight-bold">Company Address</p>
                </div>

                {/* form */}
                <Form className="d-flex flex-column flex-grow-1 justify-content-between">
                  <div>
                    <Form.Group>
                      <Form.Label>Address Line 1</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={
                          viewParticipantCompany.data.addresses[0].addressLine1
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Address Line 2</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          viewParticipantCompany.data.addresses[0].addressLine2
                        }
                        disabled
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        value={viewParticipantCompany.data.addresses[0].city}
                        disabled
                      />
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={
                              viewParticipantCompany.data.addresses[0].state
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Zip code</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            value={
                              viewParticipantCompany.data.addresses[0].zipCode
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        label="Country"
                        required
                        value={(viewParticipantCompany.data.addresses[0] && viewParticipantCompany.data.addresses[0].country)
                          ? getLabelFromArray(
                            countriesQuery.data,
                            viewParticipantCompany.data.addresses[0].country.toLowerCase())
                          : ''}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="px-3 px-md-5 d-flex flex-column flex-grow-1 my-3">
              {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}

              {viewParticipantCompany.isSuccess && (
                <div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-3 font-weight-bold">Contact Person</p>
                  </div>
                  <Form className="d-flex flex-column flex-grow-1 justify-content-between">
                    <div>
                      <Form.Group>
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={
                            viewParticipantCompany.data.contactPerson.firstName
                          }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={
                            viewParticipantCompany.data.contactPerson.lastName
                          }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Job title</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={viewParticipantCompany.data.contactPerson.role}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={
                            viewParticipantCompany.data.contactPerson
                              .phoneNumber
                          }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Contact email</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          style={{ color: 'grey' + '!important' }}
                          value={
                            viewParticipantCompany.data.contactPerson.email
                          }
                        />
                      </Form.Group>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </Col>
          <Col>
          {viewParticipantCompany.isSuccess && (
            <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 mt-3 mb-3">
              {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
              <div className="d-flex justify-content-between">
                <p className="mb-3 font-weight-bold">Delivery Address</p>
              </div>

              {/* form */}
              <Form className="d-flex flex-column flex-grow-1 justify-content-between">
                <div>
                  <Form.Group>
                    <Form.Label>Address Line 1</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={
                        viewParticipantCompany.data.deliveryAddress ? viewParticipantCompany.data.deliveryAddress.addressLine1 : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        viewParticipantCompany.data.deliveryAddress ? viewParticipantCompany.data.deliveryAddress.addressLine2 : ''
                      }
                      disabled
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      value={ viewParticipantCompany.data.deliveryAddress ? viewParticipantCompany.data.deliveryAddress.city : ''}
                      disabled
                    />
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={
                            viewParticipantCompany.data.deliveryAddress ? viewParticipantCompany.data.deliveryAddress.state : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Zip code</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={
                            viewParticipantCompany.data.deliveryAddress ? viewParticipantCompany.data.deliveryAddress.zipCode : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      label="Country"
                      required
                      value={(viewParticipantCompany.data.deliveryAddress && viewParticipantCompany.data.deliveryAddress.country)
                        ? getLabelFromArray(
                          countriesQuery.data,
                          viewParticipantCompany.data.deliveryAddress.country.toLowerCase())
                        : ''}
                      disabled
                    />
                  </Form.Group>
                </div>
              </Form>
            </div>
          )}
          </Col>
        </Row>
        <Row className="mt-0 mb-4 d-flex flex-row-reverse">
          <Button
          variant="outline-primary mx-6"

          onClick={() => setOpenDeleteParticipantModal(true)}
        >
          Delete Account
        </Button>
        </Row>
      </Layout.CardGrey>
      <ParticipantsChangeStatusModal
        openModal={showChangeStatusModal}
        setOpenModal={setShowChangeStatusModal}
        status={accountStatus}
        listingId={listingId}
      />
      <VendorDeleteParticipantModal
        deleteSingle={true}
        openModal={openDeleteParticipantModal}
        setOpenModal={setOpenDeleteParticipantModal}
        participants={[listingId]}
      />
    </Layout >
  )
}

export default Page
