/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import InfoIconSvg from '../../../images/info-sign.svg'
import { useUpdateParticipantCompanyStatus } from '../../../utils/api'
/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP, status = '', listingId = '' } = props

  // states
  const updateParticipantCompanyStatus = useUpdateParticipantCompanyStatus()

  // functions

  const onHide = () => {
    setOpenModal(false)
    updateParticipantCompanyStatus.reset()
  }

  const confirmUpdate = () => {
    let updateStatus = 'active'
    if (status === 'suspend') {
      updateStatus = 'suspended'
    } else if (status === 'approve') {
      updateStatus = 'active'
    } else if (status === 'decline') {
      updateStatus = 'declined'
    }
    updateParticipantCompanyStatus.mutate({ body: { status: updateStatus }, listingId: listingId })
  }

  let title = ''
  let message = ''
  let confirmButtonTitle = ''
  if (status === 'active') {
    title = 'Activate company?'
    message = 'Users under this account will return to their previous status.'
    confirmButtonTitle = 'Approve'
  } else if (status === 'suspend') {
    title = 'Suspend company?'
    message = 'Users under this account will also be suspended.'
    confirmButtonTitle = 'Suspend'
  } else if (status === 'approve') {
    title = 'Approve application?'
    message = 'User will be notified of successful application.'
    confirmButtonTitle = 'Approve'
  } else {
    title = 'Decline application?'
    message = 'User will be notified of unsuccessful application.'
    confirmButtonTitle = 'Decline'
  }

  return (
    <>
      <Modal show={openModal} onHide={onHide}>
        <Modal.Body>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <h5 className="m-0 mb-3" style={{ textTransform: 'capitalize' }}>
            <img src={InfoIconSvg}
              className="mr-3 mb-1"
              style={{ borderRadius: 99 + 'px', border: 4 + 'px solid #D6E1F7' }} />
            {title}
          </h5>
          <p> {message}</p>

          {/* buttons */}
          <div className="d-flex justify-content-between mt-5">
            <Button
              type="button"
              onClick={onHide}
              variant="outline-primary"
              style={{ border: 0 + 'px' }}
            >
              {updateParticipantCompanyStatus.isSuccess ? 'Close' : 'Cancel'}
            </Button>
            <Button
              type="button"
              onClick={() => { confirmUpdate() }}
              variant="primary"
              disabled={updateParticipantCompanyStatus.isSuccess}
            >
              {updateParticipantCompanyStatus.isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <span>{confirmButtonTitle}</span>
                  )}
            </Button>
          </div>

          {updateParticipantCompanyStatus.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Operation successful!</Alert>}
          {updateParticipantCompanyStatus.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error: ' + updateParticipantCompanyStatus.error.message}</Alert>}
          {/* { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>} */}
        </Modal.Body>
        {/* {process.env.NODE_ENV !== 'production' && (<pre>companiesQuery={JSON.stringify(companyInfo, null, 2)}</pre>)} */}
      </Modal>

      {/* {process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkus, null, 2)}</pre>} */}
    </>
  )
}

export default Page
